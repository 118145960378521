import antdPlPL from "antd/es/locale/pl_PL";
import plMessages from '../locales/pl_PL.json'

const pl = {
  antd: antdPlPL,
  locale: 'pl-PL',
  messages: {
    ...plMessages
  }
}

export default pl