import {AUTH_TOKEN, SIGNOUT, USER_ROLE} from "../constants/Auth";

import {SHOW_MESSAGE} from "../constants/Message";
import axios from "axios";
import {getDocuments} from "./Packaging";
import {getInventoryData} from "./Inventory";
import {getHistoryData} from "./History";
import {getAllUsers} from "./Users";
import {setIsAppLoading} from "./Theme";
import {getLabels, getPrinters} from "./LabelPrinting";
import {getSchemas} from "./Manufacturing";

const handleError = (error, dispatch) => {
    if (error?.response?.status === 401) {
        dispatch({type: SIGNOUT});
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(USER_ROLE);
        // console.clear();
        return;
    }
    if (error?.response?.status === 400 || error?.response?.status === 404) {
        return;
    }
    const message = error?.response?.statusText;
    if (message) {
        dispatch({type: SHOW_MESSAGE, message, isError: true});
    }
    // console.clear();
};

const setHeaders = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export const fetchActions = {
    get: async (url, dispatch, getState, additionalHeaders = {}) => {
        url = `${process.env.REACT_APP_BACKEND_URL}${url}`;
        const token = getState().Auth.token;
        try {
            const headers = {...setHeaders(token), ...additionalHeaders};
            const res = await axios.get(url, headers);
            return res;
        } catch (error) {
            handleError(error, dispatch);
            return error?.response;
        }
    },
    post: async (url, dispatch, getState, data, additionalHeaders = {}) => {
        url = `${process.env.REACT_APP_BACKEND_URL}${url}`;
        const token = getState().Auth.token;
        try {
            const headers = {...setHeaders(token), ...additionalHeaders};
            const res = await axios.post(url, data, headers);
            return res;
        } catch (error) {
            handleError(error, dispatch);
            return error?.response;
        }
    },
    patch: async (url, dispatch, getState, data, additionalHeaders = {}) => {
        url = `${process.env.REACT_APP_BACKEND_URL}${url}`;
        const token = getState().Auth.token;
        try {
            const headers = {...setHeaders(token), ...additionalHeaders};
            const res = await axios.patch(url, data, headers);
            return res;
        } catch (error) {
            handleError(error, dispatch);
            return error?.response;
        }
    },
    delete: async (url, dispatch, getState, additionalHeaders = {}) => {
        url = `${process.env.REACT_APP_BACKEND_URL}${url}`;
        const token = getState().Auth.token;
        try {
            const headers = {...setHeaders(token), ...additionalHeaders};
            const res = await axios.delete(url, headers);
            return res;
        } catch (error) {
            handleError(error, dispatch);
            return error?.response;
        }
    },
};

export const getAllData = () => async (dispatch) => {
    await dispatch(setIsAppLoading(true));
    await dispatch(getDocuments());
    await dispatch(getInventoryData());
    await dispatch(getHistoryData());
    await dispatch(getAllUsers());
    await dispatch(setIsAppLoading(false));
    await dispatch(getLabels());
    await dispatch(getPrinters())
    await dispatch(getSchemas())
};

export const checkStatus = (id) => async (dispatch, getState) => {
    return fetchActions.get(`/api/changes/${id}`, dispatch, getState);
};

