import {
    TOGGLE_COLLAPSED_NAV,
    CHANGE_LOCALE,
    SIDE_NAV_STYLE_CHANGE,
    NAV_TYPE_CHANGE,
    TOP_NAV_COLOR_CHANGE,
    HEADER_NAV_COLOR_CHANGE,
    TOGGLE_MOBILE_NAV,
    SWITCH_THEME,
    DIRECTION_CHANGE,
    SET_APP_LOADING, SET_TITLE, CHANGE_MANUFACTURING_TAB,
} from "../constants/Theme";
import {THEME_CONFIG} from "configs/AppConfig";

const initTheme = {
    ...THEME_CONFIG,
    isAppLoading: false,
    title: '',
    manufacturingTab: '1'
};

const theme = (state = initTheme, action) => {
    switch (action.type) {
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.navCollapsed,
            };
        case SIDE_NAV_STYLE_CHANGE:
            return {
                ...state,
                sideNavTheme: action.sideNavTheme,
            };
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        case NAV_TYPE_CHANGE:
            return {
                ...state,
                navType: action.navType,
            };
        case TOP_NAV_COLOR_CHANGE:
            return {
                ...state,
                topNavColor: action.topNavColor,
            };
        case HEADER_NAV_COLOR_CHANGE:
            return {
                ...state,
                headerNavColor: action.headerNavColor,
            };
        case TOGGLE_MOBILE_NAV:
            return {
                ...state,
                mobileNav: action.mobileNav,
            };
        case CHANGE_MANUFACTURING_TAB:
            return {
                ...state,
                manufacturingTab: action.manufacturingTab
            }
        case SWITCH_THEME:
            return {
                ...state,
                currentTheme: action.currentTheme,
            };
        case DIRECTION_CHANGE:
            return {
                ...state,
                direction: action.direction,
            };
        case SET_APP_LOADING:
            return {
                ...state,
                isAppLoading: action.isAppLoading,
            };
        case SET_TITLE:
            return {
                ...state,
                title: action.title
            }
        default:
            return state;
    }
};

export default theme;
