import {SET_SCHEMAS} from "../constants/Manufacturing";
import {SIGNOUT} from "../constants/Auth";

const initialState = {
    schemas: []
}

const manufacturing = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCHEMAS:
            return {
                ...state,
                schemas: action.schemas
            }
        case SIGNOUT:
            return initialState;
        default:
            return state
    }
}

export default manufacturing;