import { GET_HISTORY_DATA } from "redux/constants/History";
import { fetchActions } from ".";

export const getHistoryData =
  (limit = 25, page = 1, query = "") =>
  async (dispatch, getState) => {
    const role = getState().Auth.role;
    if (role < 4) return;
    const url = query
      ? `/api/history?page=${page}&limit=${limit}&${query}`
      : `/api/history?page=${page}&limit=${limit}`;
    const res = await fetchActions.get(url, dispatch, getState);
    const {
      data: historyData,
      count: totalItems,
      totalPages,
    } = res?.data || {};
    dispatch({ type: GET_HISTORY_DATA, historyData, totalPages, totalItems });
  };

export const exportHistory = () => async (dispatch, getState) => {
  const res = await fetchActions.get("/api/history/export", dispatch, getState);
  return res;
};

export const getSingleHistoryData =
  (order_number) => async (dispatch, getState) => {
    const res = await fetchActions.get(
      `/api/history/${order_number}`,
      dispatch,
      getState,
    );
    return res;
  };
