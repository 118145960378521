import {
    AUTHENTICATED,
    AUTH_TOKEN,
    HIDE_AUTH_MESSAGE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNOUT,
    SIGNOUT_SUCCESS,
    SIGNUP,
    SIGNUP_SUCCESS,
    USER_ROLE,
} from "../constants/Auth";

import axios from "axios";
import {setAuthError} from "../../utils/AuthErrors";
import {setRole} from "../../utils";
import {lockDocument} from "./Packaging";
import {fetchActions} from "./index";

export const signIn = (credentials, formatMessage) => async (dispatch) => {
    try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`;
        const res = await axios.post(url, credentials);
        const {access_token: token, role: userRole} = res?.data || {};
        const role = setRole(userRole);
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(USER_ROLE, JSON.stringify(role));
        dispatch({type: SIGNIN, token, role});
        window.location.reload();
    } catch (error) {
        const errorMessage = setAuthError(
            error?.response?.data?.error,
            formatMessage,
        );
        dispatch(showAuthMessage(errorMessage));
    }
};

export const authenticated = (token) => {
    return {
        type: AUTHENTICATED,
        token,
    };
};

export const signOut = () => async (dispatch, getState) => {
    const selectedDocument = getState().Packaging.selectedDocument;
    await dispatch(lockDocument(selectedDocument, false));
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
    dispatch({
        type: SIGNOUT,
    });
};

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS,
    };
};

export const signUp = (user) => {
    return {
        type: SIGNUP,
        payload: user,
    };
};

export const signUpSuccess = (token) => {
    return {
        type: SIGNUP_SUCCESS,
        token,
    };
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_AUTH_MESSAGE,
        message,
    };
};

export const hideAuthMessage = () => {
    return {
        type: HIDE_AUTH_MESSAGE,
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING,
    };
};

export const checkWorkerId = (workerID) => async (dispatch, getState) => {
    const res = await fetchActions.get(`/api/user-check?numer_zewnetrzny=${workerID}`, dispatch, getState);
    const isWorker = res.status === 200
    return isWorker
}
