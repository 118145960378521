import React from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import { useIntl } from "react-intl";

export const NavProfile = ({ signOut }) => {
  const { formatMessage } = useIntl();
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={"sign-out"} onClick={signOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                {formatMessage({
                  id: "global.signout",
                  defaultMessage: "Wyloguj się",
                })}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <UserOutlined size={45} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
