const { SIGNOUT } = require("redux/constants/Auth");
const { GET_ALL_USERS } = require("redux/constants/Users");

const initialState = {
  users: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.users,
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
};

export default users;
