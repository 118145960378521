import {HIDE_MESSAGE, SHOW_MESSAGE} from "../constants/Message";

const initState = {
  showMessage: false,
  message: "",
  isError: false,
};

const message = (state = initState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        showMessage: true,
        message: action.message,
        isError: action.isError
      }
    case HIDE_MESSAGE:
      return {
        ...state,
        showMessage: false,
        message: '',
        isError: false
      }
    default:
      return state;
  }
};

export default message;
