import React, {useState, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {Layout} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import Logo from "./Logo";
import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import {toggleCollapsedNav, onMobileNavToggle} from "redux/actions/Theme";
import {
    NAV_TYPE_TOP,
    SIDE_NAV_COLLAPSED_WIDTH,
    SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import MenuContent from "./MenuContent";

const {Header} = Layout;

export const HeaderNav = (props) => {
    const {
        navCollapsed,
        mobileNav,
        navType,
        headerNavColor,
        toggleCollapsedNav,
        onMobileNavToggle,
        isMobile,
        currentTheme,
    } = props;

    const title = useSelector(state => state.Theme.title)

    const [searchActive, setSearchActive] = useState(false);

    const onSearchClose = () => {
        setSearchActive(false);
    };

    const onToggle = () => {
        if (!isMobile) {
            toggleCollapsedNav(!navCollapsed);
        } else {
            onMobileNavToggle(!mobileNav);
        }
    };

    const isNavTop = navType === NAV_TYPE_TOP ? true : false;
    const mode = () => {
        if (!headerNavColor) {
            return utils.getColorContrast(
                currentTheme === "dark" ? "#00000" : "#ffffff",
            );
        }
        return utils.getColorContrast(headerNavColor);
    };
    const navMode = mode();
    const getNavWidth = () => {
        if (isNavTop || isMobile) {
            return "0px";
        }
        if (navCollapsed) {
            return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
        } else {
            return `${SIDE_NAV_WIDTH}px`;
        }
    };

    useEffect(() => {
        if (!isMobile) {
            onSearchClose();
        }
    });

    return (
        <Header
            className={`app-header ${navMode}`}
            style={{backgroundColor: headerNavColor}}>
            <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
                <Logo logoType={navMode}/>
                <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
                    <div className="nav-left">
                        {!isMobile ? null : (
                            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                                <li
                                    className="ant-menu-item ant-menu-item-only-child"
                                    onClick={() => {
                                        onToggle();
                                    }}>
                                    {navCollapsed || isMobile ? (
                                        <MenuUnfoldOutlined className="nav-icon"/>
                                    ) : (
                                        <MenuFoldOutlined className="nav-icon"/>
                                    )}
                                </li>
                            </ul>
                        )}
                        {/*  Dodanie menu w tym miejscu*/}
                        <MenuContent {...props} />
                    </div>
                    <div className='nav-title'>
                        <h2>{title}</h2>
                    </div>
                    <div className="nav-right">
                        <NavProfile/>
                    </div>
                    <NavSearch active={searchActive} close={onSearchClose}/>
                </div>
            </div>
        </Header>
    );
};

const mapStateToProps = ({Theme}) => {
    const {
        navCollapsed,
        navType,
        headerNavColor,
        mobileNav,
        currentTheme,
        direction,
    } = Theme;
    return {
        navCollapsed,
        navType,
        headerNavColor,
        mobileNav,
        currentTheme,
        direction,
    };
};

export default connect(mapStateToProps, {
    toggleCollapsedNav,
    onMobileNavToggle,
})(HeaderNav);
