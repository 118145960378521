import { fetchActions } from ".";
import { GET_ALL_USERS } from "redux/constants/Users";

export const getAllUsers =
  (query = "") =>
  async (dispatch, getState) => {
    const role = getState().Auth.role;
    if (role < 4) return;
    const url = query ? `/api/users?${query}` : "/api/users";
    const res = await fetchActions.get(url, dispatch, getState);
    const users = res?.data || [];
    dispatch({ type: GET_ALL_USERS, users });
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  await fetchActions.delete(`/api/users/${id}`, dispatch, getState);
  dispatch(getAllUsers());
};

export const addNewUser = (data) => async (dispatch, getState) => {
  await fetchActions.post(`/api/users`, dispatch, getState, data);
  dispatch(getAllUsers());
};

export const updateUser = (data, id) => async (dispatch, getState) => {
    await fetchActions.patch(`/api/users/${id}`, dispatch, getState, data);
    dispatch(getAllUsers());
};
