import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();



const MenuContent = (props) => {
  const { topNavColor, localization, role } = props;
  return (
      <Menu mode="horizontal" >
        {navigationConfig(role)?.map((menu, index) =>
            menu?.submenu?.length > 0 ? (
                <SubMenu
                    key={menu?.key || index}
                    popupClassName="top-nav-menu"
                    title={
                      <span>
                {menu?.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
              </span>
                    }>
                  {menu?.submenu?.map((subMenuFirst, index) =>
                      subMenuFirst.submenu.length > 0 ? (
                          <SubMenu
                              key={subMenuFirst?.key || index}
                              icon={
                                subMenuFirst.icon ? (
                                    <Icon type={subMenuFirst?.icon} />
                                ) : null
                              }
                              title={setLocale(localization, subMenuFirst?.title)}>
                            {subMenuFirst?.submenu?.map((subMenuSecond, index) => (
                                <Menu.Item key={subMenuSecond?.key || index}>
                                  <Link to={subMenuSecond?.path} />
                                </Menu.Item>
                            ))}
                          </SubMenu>
                      ) : (
                          <Menu.Item key={subMenuFirst?.key || index}>
                            {subMenuFirst?.icon ? (
                                <Icon type={subMenuFirst?.icon} />
                            ) : null}
                            <Link to={subMenuFirst?.path} />
                          </Menu.Item>
                      ),
                  )}
                </SubMenu>
            ) : (
                <Menu.Item key={menu?.key || index}>
                  {menu?.icon ? <Icon type={menu?.icon} /> : null}
                  {menu?.path ? <Link to={menu?.path} /> : null}
                </Menu.Item>
            ),
        )}
      </Menu>
      )
};

const mapStateToProps = ({ Auth, Theme }) => {
  const { role } = Auth;
  const { sideNavTheme, topNavColor } = Theme;
  return { role, sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
