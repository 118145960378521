import { GET_LABELS, SET_PRINTERS } from "redux/constants/LabelPrinting";
import { fetchActions } from ".";
import { SHOW_MESSAGE } from "redux/constants/Message";

export const getLabels = () => async (dispatch, getState) => {
  const { role } = getState().Auth;
  if (+role <= 2) return;
  const res = await fetchActions.get("/api/quick-print", dispatch, getState);
  const { data } = res || {};
  dispatch({ type: GET_LABELS, labels: data || [] });
};

export const printLabel =
  (id, quantity, printer) => async (dispatch, getState) => {
    const res = await fetchActions.post(
      "/api/quick-print/print",
      dispatch,
      getState,
      {
        id,
        quantity,
        type: "normal",
        printer,
      },
    );
    if (res.status === 200) {
      dispatch({
        type: SHOW_MESSAGE,
        message: "Drukowanie zakończyło się sukcesem",
        isError: false,
      });
    }
  };

export const getPrinters = () => async (dispatch, getState) => {
  try {
    const { role } = getState().Auth;
    if (+role <= 2) return;
    const avaliablePrinters = [
      "arizona",
      "kiosk",
      "zdesigner gk420d",
      "kiosk",
      "zebra",
    ];
    const res = await fetchActions.get(
      "/api/quick-print/printers",
      dispatch,
      getState,
    );
    const { data = [] } = res || {};
    const printers = data
      ?.reduce((acc, printer) => {
        const name = avaliablePrinters.find(
          (name) =>
            printer.name.toLowerCase().includes(name) ||
            printer.computer.hostname.toLowerCase().includes(name) ||
            printer.computer.name.toLowerCase().includes(name),
        );
        if (name) {
          return [
            ...acc,
            {
              id: printer.id,
              name: `${printer.name} - ${printer.computer.name}`,
            },
          ];
        }
        return acc;
      }, [])
      .sort((a, b) => b.id - a.id);
    dispatch({ type: SET_PRINTERS, printers });
  } catch (e) {
    console.log(e);
  }
};

export const importFile = (data) => async (dispatch, getState) => {
  const res = await fetchActions.post(
    "/api/quick-print/import",
    dispatch,
    getState,
    {
      data,
    },
  );
  if (res.status === 200) {
    dispatch({
      type: SHOW_MESSAGE,
      message: "Import zakończył się sukcesem",
      isError: false,
    });
  }
};
