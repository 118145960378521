import {SIGNOUT} from "redux/constants/Auth";
import {GET_DOCUMENTS, SET_DOCUMENT, SET_FILE, SET_INITIAL_DOCUMENT} from "redux/constants/Packaging";

const initState = {
    documentsLength: 0,
    selectedDocument: null,
    documents: [],
    file: null,
    headers: {},
    initialDocument: null
};

const packaging = (state = initState, action) => {
    switch (action.type) {
        case GET_DOCUMENTS:
            return {
                ...state,
                documents: action.documents,
                documentsLength: action.documentsLength,
            };
        case SIGNOUT:
            return initState;
        case SET_DOCUMENT:
            return {
                ...state,
                selectedDocument: action.selectedDocument,
            };
        case SET_FILE:
            return {
                ...state, file: action.file, headers: action.headers
            }
        case SET_INITIAL_DOCUMENT:
            return {
                ...state, initialDocument: {
                    number: 1,
                    ...action.document
                }
            }
        default:
            return state;
    }
};

export default packaging;
