import { SIGNOUT } from "redux/constants/Auth";
import {GET_LABELS, SET_PRINTERS} from "redux/constants/LabelPrinting";

const initialState = {
  labels: [],
  printers: []
};

const labelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS:
      return {
        ...state,
        labels: action.labels,
      };
    case SET_PRINTERS:
      return {
        ...state,
        printers: action.printers
      }
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
};

export default labelsReducer;
