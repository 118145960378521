export const setAuthError = (code, formatMessage) => {
    switch(code) {
        case "Unauthorized":
            return formatMessage({
                id:'UnathorizedError',
                defaultMessage: 'Niepoprawne dane'
            })
        default:
            return formatMessage({
                id:'Error',
                defaultMessage: 'Coś poszło nie tak, spróbuj ponownie później'
            });
    }
}