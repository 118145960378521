import {combineReducers} from "redux";
import Auth from "./Auth";
import History from "./History";
import Inventory from "./Inventory";
import Message from "./Message";
import Packaging from "./Packaging";
import Theme from "./Theme";
import Users from "./Users";
import LabelPrinting from "./LabelPrinting";
import Manufacturing from "./Manufacturing";

const reducers = combineReducers({
    Auth,
    Inventory,
    History,
    LabelPrinting,
    Manufacturing,
    Message,
    Packaging,
    Theme,
    Users,
});

export default reducers;
