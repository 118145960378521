import {
    AppstoreOutlined,
  BarcodeOutlined,
  TableOutlined,
  HistoryOutlined,
  UserOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "./AppConfig";

const navigationConfig = (role = 1) => [
  role >= 2
    ? {
        key: "packing",
        path: `${APP_PREFIX_PATH}/packing`,
        title: "sidenav.packing",
        icon: BarcodeOutlined,
        breadcrumb: false,
        submenu: [],
      }
    : null,
    role >= 3
        ? {
            key: "manufacturing",
            path: `${APP_PREFIX_PATH}/manufacturing`,
            title: "sidenav.manufacturing",
            icon: AppstoreOutlined,
            breadcrumb: false,
            submenu: [],
        }
        : null,
  {
    key: "inventories",
    path: `${APP_PREFIX_PATH}/inventories`,
    title: "sidenav.inventories",
    icon: TableOutlined,
    breadcrumb: false,
    submenu: [],
  },
  role >= 3
    ? {
        key: "labelPrinting",
        path: `${APP_PREFIX_PATH}/label-printing`,
        title: "sidenav.labelPrinting",
        icon: PrinterOutlined,
        breadcrumb: false,
        submenu: [],
      }
    : null,
  role >= 4
    ? {
        key: "order-history",
        path: `${APP_PREFIX_PATH}/order-history`,
        title: "sidenav.orderHistory",
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      }
    : null,
  role >= 4
    ? {
        key: "users",
        path: `${APP_PREFIX_PATH}/users`,
        title: "sidenav.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      }
    : null,
  // role >= 4
  //     ? {
  //         key: "analitics",
  //         path: `${APP_PREFIX_PATH}/analitics`,
  //         title: "sidenav.analitics",
  //         icon: AreaChartOutlined,
  //         breadcrumb: false,
  //         submenu: [],
  //     }
  //     : null,
];

export default navigationConfig;
