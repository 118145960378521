import { GET_INVENTORY_DATA } from "../constants/Inventory";
import { fetchActions } from ".";

export const getDataForExcel =
  (limit = 10, page = 1, query = "") =>
  async (dispatch, getState) => {
    const url = query
      ? `/api/stocks?page=${page}&limit=${limit}&${query}`
      : `/api/stocks?page=${page}&limit=${limit}`;
    const res = await fetchActions.get(url, dispatch, getState);
    return res;
  };

export const getInventoryData =
  (limit = 10, page = 1, query = "") =>
  async (dispatch, getState) => {
    const url = query
      ? `/api/stocks?page=${page}&limit=${limit}&${query}`
      : `/api/stocks?page=${page}&limit=${limit}`;
    const res = await fetchActions.get(url, dispatch, getState);
    const {
      data,
      limit: pageLimit,
      totalPages,
      count: totalItems,
    } = res?.data || {};
    dispatch({
      type: GET_INVENTORY_DATA,
      inventoryData: data,
      limit: pageLimit,
      totalPages,
      totalItems,
    });
  };

export const changeInventoryData =
  (type, reference, oldValue, newValue, reason) =>
  async (dispatch, getState) => {
    const locationData = {
      stara_lokalizacja: oldValue,
      nowa_lokalizacja: newValue,
    };
    const stockData = {
      stary_stan: oldValue,
      nowy_stan: newValue,
      powod_zmiany: reason,
    };
    const data = {
      type,
      reference,
      ...(type === "ZMIANA_LOKALIZACJI"
        ? { data: { ...locationData } }
        : { data: { ...stockData } }),
    };
    const res = await fetchActions.post(
      "/api/changes",
      dispatch,
      getState,
      data,
    );
    return res;
  };

export const getStocks = (indeksyKatalogowe) => async (dispatch, getState) => {
  const res = await fetchActions.post("/api/stocks/file", dispatch, getState, {
    indeksyKatalogowe,
  });
  return res?.data || [];
};

export const printLabel = (indeks_handlowy, quantity, printer, documentSize) => async (dispatch, getState) => {
    const res = await fetchActions.post("/api/stocks/print", dispatch, getState, {
        id: indeks_handlowy,quantity,printer,documentSize
    });
    return res?.data || [];
};