import {GET_DOCUMENTS, SET_DOCUMENT, SET_FILE, SET_INITIAL_DOCUMENT} from "redux/constants/Packaging";
import {fetchActions} from "./index";

export const getDocuments = () => async (dispatch, getState) => {
    const initialDocument = getState().Packaging.initialDocument
    const role = getState().Auth.role;
    if (role < 2) return;
    const res = await fetchActions.get("/api/documents", dispatch, getState);
    const {data} = res?.data || [];

    const documents = data?.filter((doc) => !doc.error);

    if (!initialDocument) {
        dispatch({type: SET_INITIAL_DOCUMENT, document: documents?.[0]})
    }

    dispatch({
        type: GET_DOCUMENTS,
        documents,
        documentsLength: documents?.length,
    });
};

export const getDocument = (name) => async (dispatch, getState) => {
    if (!name) return
    const res = await fetchActions.get(`/api/documents/${name}`, dispatch, getState, {
        responseType: "arraybuffer",
    });
    const wantsInvoice = +res.headers["custom-wants-invoice"];
    const paymentsCod = +res.headers["custom-cod"];
    const is_pickup = res.headers["custom-pickup"]
        ? JSON.parse(res.headers["custom-pickup"])
        : false;
    const pickup_place = res.headers["custom-pickup-place"];
    dispatch({type: SET_FILE, file: res, headers: {wantsInvoice, pickup_place, paymentsCod, is_pickup}})
    return res;
};

export const lockDocument = (id, is_locked) => async (dispatch, getState) => {
    await fetchActions.post(`/api/documents/${id}/lock`, dispatch, getState, {
        is_locked,
    });
};

export const sendPackage = (data, type, id) => async (dispatch, getState) => {
    const url = type === "patch" ? `/api/changes/${id}` : "/api/changes";
    const res = await fetchActions[type](url, dispatch, getState, data);
    dispatch(getDocuments());
    return res;
};

export const setSelectedDocument = (selectedDocument) => (dispatch) => {
    dispatch({type: SET_DOCUMENT, selectedDocument});
};

export const resetOrder = (order_number) => async (dispatch, getState) => {
    const url = `/api/changes?reference=${order_number}&status=ERROR`;
    const res = await fetchActions.get(url, dispatch, getState);
    const {data} = res;
    if (Array.isArray(data)) {
        for (const document of data) {
            const {id} = document;
            await fetchActions.delete(`/api/changes/${id}`, dispatch, getState);
        }
    } else {
        const {id} = data;
        await fetchActions.delete(`/api/changes/${id}`, dispatch, getState);
    }
};

export const deleteDocument = (order_number) => async (dispatch, getState) => {
    const url = `/api/documents/${order_number}`;
    await fetchActions.delete(url, dispatch, getState);
}

export const setDocument = (index) => async (dispatch, getState) => {
    const initialDocument = getState().Packaging.initialDocument
    const documents = getState().Packaging.documents
    const documentsLength = getState().Packaging.documentsLength
    if (index === undefined) return
    if (index === null) {
        dispatch(setSelectedDocument(initialDocument))
        return;
    }

    if (index - 1 < documentsLength) {
        dispatch(setSelectedDocument({
            number: index,
            ...documents[index - 1]
        }))
    }

}
