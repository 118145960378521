import { SIGNOUT } from "redux/constants/Auth";
import { GET_INVENTORY_DATA } from "../constants/Inventory";

const initialState = {
  inventoryData: [],
  pages: 1,
  limit: 10,
  totalPages: 1,
  totalItems: 10,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_DATA:
      return {
        ...state,
        inventoryData: action.inventoryData,
        limit: action.limit,
        totalPages: action.totalPages,
        totalItems: action.totalItems,
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
};

export default inventoryReducer;
