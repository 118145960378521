import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Message from "components/layout-components/Message";
import { getAllData } from "redux/actions";
import Loading from "components/shared-components/Loading";

const RouteInterceptor = (props) => {
  const { children, getAllData, isAuthenticated, isAppLoading, ...rest } =
    props;

  useEffect(() => {
    if (!isAuthenticated) return;
    getAllData();
  }, [isAuthenticated, getAllData]);

  if (isAppLoading) return <Loading cover="content" />;

  return (
    <>
      <Message />
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};

const mapStateToDispatch = {
  getAllData,
};

const mapStateToProps = ({ Theme }) => {
  const { isAppLoading } = Theme;
  return { isAppLoading };
};

export default connect(mapStateToProps, mapStateToDispatch)(RouteInterceptor);
