import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Modal, Alert} from "antd";
import {hideMessage} from "redux/actions/Message";

const Message = (props) => {
    const {hideMessage, isError, message, showMessage} = props

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                hideMessage();
            }, 4000);
        }
    }, [showMessage]);

    return <Modal visible={showMessage} footer={null} closable={false} destroyOnClose onCancel={hideMessage}>
        <Alert
            type={isError ? 'error' : 'success'}
            showIcon
            message={message}
        />
    </Modal>;
};


const mapStateToProps = ({Message}) => {
    const {showMessage, message, isError} = Message;
    return {
        showMessage,
        message,
        isError,
    };
};

export default connect(mapStateToProps, {hideMessage})(Message);
