import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Views from "./views";
import {ThemeSwitcherProvider} from "react-css-theme-switcher";
import {THEME_CONFIG} from "./configs/AppConfig";

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

    useEffect(() => {
        localStorage.setItem('login_timestamp', JSON.stringify(new Date().getTime()))
        window.addEventListener('resize', () => {
            const isKiosk = window.matchMedia('(min-height: 1200px)').matches
            const content = document.querySelector('.ant-layout-content')
            if (isKiosk) {
                document.body.classList.add('padding')
                content.classList.add('padding')
            } else {
                document.body.classList.remove('padding')
                content.classList.remove('padding')
            }
        })
    }, []);

    return (
        <div className="App">
            <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point">
                <Router>
                    <Switch>
                        <Route path="/" component={Views}/>
                    </Switch>
                </Router>
            </ThemeSwitcherProvider>
        </div>
    );
}

export default App;
