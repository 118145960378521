import { SIGNOUT } from "redux/constants/Auth";
import { GET_HISTORY_DATA } from "redux/constants/History";

const initialState = {
  historyData: [],
  totalPages: 1,
  totalItems: 10,
};

const history = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_DATA:
      return {
        ...state,
        historyData: action.historyData,
        totalPages: action.totalPages,
        totalItems: action.totalItems,
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
};

export default history;
